<template>
  <div>
    <van-nav-bar
      title="我的消息"
      left-text="返回"
      left-arrow
      @click-left="Bank"
    />
    <div class="content">
      <div class="title-box">
        <div class="title">{{ content }}</div>
        <div class="title-time-box">
          <div class="time">{{ time }}</div>
        </div>
      </div>
      <div class="cropList-wrap">
        <div class="cropList-main">
          <div
            class="crop"
            v-for="(item, index) in cropList.infoAttachList"
            :key="index"
          >
            <div class="img">
              <img
                style="height: 90px; width: 110px; background: #ddd;"
                :src="item.full_path"
                v-if="item.full_path"
              />
              <div
                v-else
                style="height: 90px; width: 110px; background: #ddd;"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="header-time">
        <div class="header-img">
          <img :src="header" alt="" style="height: 76px;" />
        </div>
        <div class="time-name">
          <div class="user">{{ replyUser }}</div>
          <div class="replyTime">{{ replyTime }}</div>
        </div>
      </div>
      <div class="content-box">
        <div class="content">
          {{ reply }}
        </div>
      </div>
      <div class="button">
        <van-button type="info" @click="handleDel" style="background:#001f90">删除</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { NavBar, Button, Dialog, Notify } from "vant";
import { postInfoDetail, postInfoDelete } from "@/service/info";
export default {
  name: "messageDetail",
  data() {
    return {
      cropList: [],
      content: "",
      time: "",
      replyUser: "",
      replyTime: "",
      reply: "",
      header: require("../../assets/headerImg/header.svg"),
      CropImgNihoutao: require("../../assets/image/crop/crop_mihoutao.png"),
      CropImgYanye: require("../../assets/image/crop/crop_yancao.png"),
      CropImgSrc: "",
      id: ""
    };
  },
  components: {
    VanNavBar: NavBar,
    VanButton: Button,
    [Dialog.Component.name]: Dialog.Component,
    [Notify.Component.name]: Notify.Component
  },
  mounted() {
    this.getlistid();
  },
  methods: {
    Bank() {
      this.$router.go(-1);
    },
    getlistid() {
      this.id = this.$route.params && this.$route.params.id;
      postInfoDetail(this.id).then(res => {
        const data = res.data;
        this.cropList = data;
        this.content = data.content;
        this.time = data.create_time;
        this.replyUser = data.reply_user;
        this.replyTime = data.reply_time;
        this.reply = data.reply;
        if (data.crop_id === 1) {
          this.CropImgSrc = this.CropImgNihoutao;
        } else if (data.crop_id === 2) {
          this.CropImgSrc = this.CropImgYanye;
        }
      });
    },
    handleDel() {
      Dialog.confirm({
        title: "删除",
        message: "确定删除"
      })
        .then(() => {
          postInfoDelete(this.id).then(res => {
            if (res.code === 200) {
              Notify({ type: "success", message: "成功删除" });
              this.$router.go(-1);
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.content {
  position: relative;
  margin: 15px;
  .title-box {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
  }
  .title-time-box {
    display: flex;
    font-size: 14px;
    font-weight: 100;
  }
  .cropList-wrap {
    margin: 10px 10px 0;
    .title {
      color: #333;
      font-weight: 600;
      font-size: 18px;
      margin: 20px 0;
    }
    .cropList-main {
      padding-bottom: 16px;
      display: flex;
      overflow: auto;
      .crop {
        .name {
          font-size: 14px;
          width: 100%;
          color: #333;
          margin-top: 6px;
          text-align: center;
        }
        & + .crop {
          margin-left: 10px;
        }
      }
    }
  }
  .header-time {
    display: flex;
    .time-name {
      margin-left: 10px;
      .user {
        padding-top: 5px;
        padding-bottom: 20px;
      }
      .replyTime {
        padding-top: 15px;
      }
    }
  }
  .content-box {
    margin-top: 5px;
    .content {
      line-height: 28px;
    }
  }
  .button {
    position: absolute;
    left: 34.66667vw;
  }
}
</style>
